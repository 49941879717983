import React, { useState, useEffect } from "react";
import Navbar from '../components/Navbar';
import image1 from '../images/image1.png';
import image2 from '../images/image2.png';
import image3 from '../images/image3.png';
import course1 from '../images/c.jpg';
import course2 from '../images/d.jpg';
import course3 from '../images/e.jpg';
import CountUp from 'react-countup';
import Banner1 from '../images/Banner1.jpg'
import c1 from '../images/c1.jpg'
import c2 from '../images/c2.jpg'
import c3 from '../images/c3.jpg'
import training from '../images/training.jpg'
import Banner2 from '../images/Banner2.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faStarHalfAlt, faStarHalfStroke } from '@fortawesome/free-solid-svg-icons'
import BaseNavBar from "../Base Page/BaseNavBar";

import { Fade } from "@mui/material";
import Grow from '@mui/material/Grow';
import { Zoom } from "react-reveal";
import Slide from 'react-reveal/Slide';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Grid } from "@mui/material";
import Typography from '@mui/material/Typography';
import { CheckCircle, CheckCircleOutline } from '@mui/icons-material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';


import axios from "axios";
import config from './config.json';



//const cookies = new Cookies();
//const BASE_URL = "http://10.240.13.181:8888";
const BASE_URL = config.SERVER_URL;
const KEY = config.KEY;
const TITLE = config.TITLE;






export default function Home() {

    const [center, setcenter] = useState();
    const [ben, setben] = useState();
    const [fac, setfac] = useState();
    const [cert, setcert] = useState();
    const [checked, setchecked] = useState(true)

    const [modalopen, setmodalopen] = useState(false)

    const [value, setValue] = useState('1');





    useEffect(() => {

        const headers = {
            'publicKey': KEY

        }

        axios.post(BASE_URL + '/mastercounter', {}, { headers: headers })
            .then(function (responses) {

                console.log("Counts", responses.data.Counts)
                //setunmapped(responses.data.Beneficiaries)
                //setbens(responses.data.Beneficiaries)
                console.log("first", responses.data.Counts.centers)
                setcenter(responses.data.Counts.centers)
                setben(responses.data.Counts.bens)
                setfac(responses.data.Counts.faculties)
                setcert(responses.data.Counts.certificates)

            })
            .catch(function (errors) {

            })
    }, [])

    const handlemodalClose = () => {
        setmodalopen(false);
    };

    const onmodalopen = (cellValues) => {
        console.log("value ", cellValues)

        setValue(cellValues)
        setmodalopen(true)



    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <div>
                <BaseNavBar title={TITLE}></BaseNavBar>
                {/* carousel starts */}
                <div className="row">
                    <div className="container ">
                        <div id="carouselExampleIndicators" className="carousel slide carousel-fade" data-bs-ride="carousel">
                            <div className="carousel-indicators">
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                {/* <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button> */}
                            </div>
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <img src={training} className="d-block w-100" alt="image1" />
                                </div>
                                {/* <div className="carousel-item">
                                    <img src={Banner2} className="d-block w-100" alt="image2" />
                                </div> */}

                            </div>
                            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="sr-only">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="sr-only">Next</span>
                            </button>
                        </div>
                    </div>
                </div>
                {/* carousel ends */}
                {/* centre card starts */}
                <div className="row m-5 justify-content-center ">
                    <div className="col-md-3 key-div">
                        <div className="key-cover">
                            {/* <i className="far fa-file-word"></i> */}
                            <span className="counter span"><CountUp end={2} /></span>
                            <h4>Centres</h4>
                        </div>
                    </div>
                    
                    <div className="col-md-3 key-div">
                        <div className="key-cover">
                            {/* <i className="fas fa-object-group"></i> */}
                            <span className="counter span"><CountUp end={4635} /></span>
                            <h4>Enrolled Beneficiaries</h4>
                        </div>
                    </div>
                    <div className="col-md-3 key-div">
                        <div className="key-cover">
                            {/* <i className="fas fa-phone-volume"></i> */}
                            <span className="counter span"><CountUp end={4328} /></span>
                            <h4>Certification Awarded</h4>
                        </div>
                    </div>
                </div>
                {/* center card ends */}
                {/* courses starts */}
                <div className="cources container-fluid mb-5  ">
                    <div className="container">
                        <div className=" row" style={{ textalign: "center" }}>
                            <h2>Our Courses</h2>
                            <p>The Following courses are offered in our training centers</p>
                        </div>


                        <div className="cours-ro row">

                            <Zoom>
                                <div className="col-md-4 col-sm-6 cord-div">
                                    <div className="cours-card cd ">
                                        <img src={c1} className="w-100  " border="1" alt="" />
                                        <div className="cours-name mt-2">
                                            <h3 style={{ textalign: "center" }}>Basics Of IT and Soft Skills</h3>
                                        </div>
                                        {/* <div className="course-detail">
                                            <h5>Course Details:</h5>
                                            <ul >
                                                <li>Introduction to Graphic Design</li>
                                                <li>Application of Photoshop &amp; CAD
                                                    software tools</li>
                                            </ul>
                                            

                                        </div> */}
                                    </div>
                                </div>
                            </Zoom>




                            <Zoom
                            >

                                <div className="col-md-4 col-sm-6 cord-div">
                                    <div className="cours-card cd">
                                        <img src={c2} className="w-100" alt="" border="1" />
                                        <div className="cours-name mt-2">
                                            <h3 style={{ textalign: "center" }}>Design Development</h3>
                                        </div>
                                        {/* <div className="course-detail">
                                            <h5>Course Details:</h5>
                                            <ul >
                                                <li>Introduction to Graphic Design</li>
                                                <li>Application of Photoshop &amp; CAD
                                                    software tools</li>
                                            </ul>
                                            

                                        </div> */}
                                    </div>
                                </div>

                            </Zoom>

                            <Zoom
                            >
                                <div className="col-md-4 col-sm-6 cord-div " >
                                    <div className="cours-card cd "  >
                                        <img src={c3} className="w-100 " alt="" border="1" />
                                        <div className="cours-name mt-2">
                                            <h3 style={{ textalign: "center" }}>Packaging &amp; Quality Control</h3>
                                        </div>
                                        {/* <div className="course-detail">
                                            <h5>Course Details:</h5>
                                            <ul >
                                                <li>Basics of Design Software</li>
                                                <li>Operation of Sublimation Machine</li>
                                            </ul>

                                            

                                        </div> */}
                                    </div>
                                </div>
                            </Zoom>

                            <div className=" row mt-5" style={{ textalign: "center", marginTop: 8 }}>
                                <h2>Course Details</h2>

                            </div>

                            <Box sx={{ width: '100%', height: 400, typography: 'body1',boxShadow: 5, marginTop: 5, background: "#fafafa" }}>
                                <TabContext value={value}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <TabList onChange={handleChange} centered aria-label="lab API tabs example">
                                            <Tab label="Basics Of IT and Soft Skills" value="1" />
                                            <Tab label="Design Development" value="2" />
                                            <Tab label="Packaging &amp; Quality Control" value="3" />
                                        </TabList>
                                    </Box>
                                    <TabPanel value="1">
                                        <Typography variant="subtitle1" >
                                            1.
                                            Computer Fundamental & MS Windows.
                                        </Typography>
                                        <Typography variant="subtitle1" >
                                            2.
                                            Internet and Online Banking using various systems.
                                        </Typography>
                                        <Typography variant="subtitle1" >
                                            3.
                                            Document Preparation using MS Word.
                                        </Typography>
                                        <Typography variant="subtitle1" >
                                            4.
                                            Working With Spreadsheet using MS Excel.
                                        </Typography>
                                        <Typography variant="subtitle1" >
                                            5.
                                            Creating Presentation using MS PowerPoint.
                                        </Typography>
                                        <Typography variant="subtitle1" >
                                            6.
                                            Soft Skill (Spoken English, Preparing Presentation,  Writing Letters)
                                        </Typography>
                                    </TabPanel>
                                    <TabPanel value="2">
                                        <Typography variant="subtitle1" >
                                            1.
                                            Introduction to Graphic Design.
                                        </Typography>
                                        <Typography variant="subtitle1" >
                                            2.
                                            Application of Photoshop & CAD software tools.
                                        </Typography>
                                        <Typography variant="subtitle1" >
                                            3.
                                            Motif Creation using Design Software.
                                        </Typography>
                                        <Typography variant="subtitle1" >
                                            4.
                                            Design creation using Photoshop & CAD software.
                                        </Typography>
                                        <Typography variant="subtitle1" >
                                            5.
                                            Operation of various Digital devices for design creation.
                                        </Typography>
                                        <Typography variant="subtitle1" >
                                            6.
                                            Operation of loom & complete Jacquard loom Machine.
                                        </Typography>
                                        <Typography variant="subtitle1" >
                                            7.
                                            Operation of Paddle cum power Handloom Machine & machinery tools.
                                        </Typography>
                                        <Typography variant="subtitle1" >
                                            8.
                                            e-tailoring application for product customization.
                                        </Typography>
                                        <Typography variant="subtitle1" >
                                            9.
                                            Operation of Plotter
                                        </Typography>
                                    </TabPanel>
                                    <TabPanel value="3">
                                        <Typography variant="subtitle1" >
                                            1.
                                            Basics of Design Software.
                                        </Typography>
                                        <Typography variant="subtitle1" >
                                            2.
                                            Operation of Sublimation Machine.
                                        </Typography>
                                        <Typography variant="subtitle1" >
                                            3.
                                            Operation of Computer Embroidery Machine & machinery tool.
                                        </Typography>
                                        <Typography variant="subtitle1" >
                                            4.
                                            Operation of Computer Interlock sewing Machine & machinery tool.
                                        </Typography>
                                        <Typography variant="subtitle1" >
                                            5.
                                            Packaging Fundamentals.
                                        </Typography>
                                        <Typography variant="subtitle1" >
                                            6.
                                            Quality control.
                                        </Typography>
                                        <Typography variant="subtitle1" >
                                            7.
                                            Marketing Skills.
                                        </Typography>
                                    </TabPanel>
                                </TabContext>
                            </Box>





                        </div>

                    </div>


                </div>

                {/* courses ends */}

            </div>
        </>
    )
}
