import React from 'react'
import BaseNavBar from './BaseNavBar'
import Banner1 from '../images/Banner1.jpg'
import Banner2 from '../images/Banner2.jpg'
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import e1 from "../images/btc.png"
import cdac from "../images/cdac.png"
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import config from '../components/config.json'
import Slide from 'react-reveal/Slide';
import { useState } from 'react';
import { useEffect } from 'react';
import { Cookies } from 'react-cookie';
import axios from 'axios';

import Modal from '@mui/material/Modal';
import Card from '@mui/material/Card';

import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import login from '../images/login.jpg';
import trainingimg from '../images/trainingimg.jpg'
import eventimg from '../images/eventsimg.jpg'
import infraimg from '../images/infraimg.jpg'


import ImageGallery from "react-image-gallery";
// import "react-image-gallery/styles/css/image-gallery.css";




const cookies = new Cookies();
const BASE_URL = config.SERVER_URL;
const KEY = config.KEY;
const TITLE = config.TITLE;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: 4

};


export default function BasePage() {

  const [images, setimages] = useState(null);
  const [infra, setinfra] = useState(null);
  const [event, setevent] = useState(null);

  const [galleryb, setgalleryb] = useState(false)
  const [modalopenform, setmodalopenform] = useState(false)

  const [img, setimg] = useState(null)




  useEffect(() => {
    const headers = {
      'publicKey': KEY

    }

    const params = new URLSearchParams();
    params.append("gallery_category_id", 1);

    const params2 = new URLSearchParams();
    params2.append("gallery_category_id", 2);

    const params3 = new URLSearchParams();
    params3.append("gallery_category_id", 3);

    const training = axios.post(BASE_URL + "/getallgalleryitems", params, {
      headers: headers,
    });

    const infra = axios.post(BASE_URL + "/getallgalleryitems", params2, {
      headers: headers,
    });

    const event = axios.post(BASE_URL + "/getallgalleryitems", params3, {
      headers: headers,
    });

    Promise.all([training, infra, event])
      .then(function (responses) {
        setimages(
          responses[0].data.GalleryItems.map(url => ({
            original: `${BASE_URL + "/" + "gallery" + "/" + url.gallery_id}`,
            thumbnail: `${BASE_URL + "/" + "gallery" + "/" + url.gallery_id}`,
            originalHeight: "500px",
            originalAlt: url.image_description,
            originalTitle: url.image_description,
            thumbnailTitle: url.image_description,
            description: url.image_description,




          }))
        )
        setinfra(
          responses[1].data.GalleryItems.map(url => ({
            original: `${BASE_URL + "/" + "gallery" + "/" + url.gallery_id}`,
            thumbnail: `${BASE_URL + "/" + "gallery" + "/" + url.gallery_id}`,
            originalHeight: "500px",
            originalAlt: url.image_description,
            originalTitle: url.image_description,
            thumbnailTitle: url.image_description,
            description: url.image_description,



          }))
        )
        setevent(
          responses[2].data.GalleryItems.map(url => ({
            original: `${BASE_URL + "/" + "gallery" + "/" + url.gallery_id}`,
            thumbnail: `${BASE_URL + "/" + "gallery" + "/" + url.gallery_id}`,
            originalHeight: "500px",
            originalAlt: url.image_description,
            originalTitle: url.image_description,
            thumbnailTitle: url.image_description,
            description: url.image_description,



          }))
        )



      })
      .catch(function (errors) {
        console.log("training errors", errors[0]);
        console.log("infra errors", errors[1]);

      });





    // axios.post(BASE_URL + '/getallgalleryitems', params, { headers: headers })
    //     .then(function (responses) {

    //         console.log("GalleryItems", responses.data.GalleryItems)
    //         //setunmapped(responses.data.Beneficiaries)
    //         //setbens(responses.data.Beneficiaries)

    //         if (responses.data.GalleryItems) {
    //             setimages(
    //                 responses.data.GalleryItems.map(url => ({
    //                     original: `${BASE_URL + "/" + "gallery" + "/" + url.gallery_id}`,
    //                     thumbnail: `${BASE_URL + "/" + "gallery" + "/" + url.gallery_id}`,
    //                     originalAlt: url.image_description,
    //                     originalTitle: url.image_description,
    //                     thumbnailTitle: url.image_description,
    //                     description: url.image_description,
    //                     sizes: 4


    //                 })))
    //         }

    //         console.log("imgs ", images)

    //     })
    //     .catch(function (errors) {

    //     })


  }, [])



  const handletrainingclick = () => {

    setimg(images)
    setgalleryb(true)
    setmodalopenform(true);

  }

  const handleeventclick = () => {

    setimg(event)
    setgalleryb(true)
    setmodalopenform(true);

  }

  const handleinfraclick = () => {

    setimg(infra)
    setgalleryb(true)
    setmodalopenform(true);

  }

  const handleformmodalClose = () => {
    setmodalopenform(false);
  };

  const handlemodalClose = () => {
    setmodalopenform(false);
  }

  return (
    <>
      <BaseNavBar title={TITLE}></BaseNavBar>
      {/* carousel starts */}
      <div className="row">
        <div className="container ">
          <div id="carouselExampleIndicators" className="carousel slide carousel-fade" data-bs-ride="carousel">
            <div className="carousel-indicators">
              <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
              <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
              {/* <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button> */}
            </div>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img src={Banner1} className="d-block w-100" alt="image1" />
              </div>
              <div className="carousel-item">
                <img src={Banner2} className="d-block w-100" alt="image2" />
              </div>

            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="sr-only">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="sr-only">Next</span>
            </button>
          </div>
        </div>
      </div>
      {/* carousel ends */}
      <div className='container'>

        <div className=" text-black text-center col 10 p-1 mt-5">
          <h3> ABOUT BODOLAND </h3>
        </div>
        <Box
          component="form"
          display="flex"
          justifyContent="center"
          alignItems="center"

          sx={{
            marginTop: 8,
            // display: "flex",
            // flexDirection: "column"


          }}
          noValidate
          autoComplete="off"
        >
          <Slide left>

            <Grid container spacing={6} direction="row"
              alignItems={"center"}
              justifyContent={"center"}>


              <Grid item xs={12} md={6} lg={6} alignItems="right" >
                <img src={e1} className="w-20" alt="" />

              </Grid>

              <Grid item xs={12} md={6} lg={6} >
                <Typography variant="subtitle1" align='justify'>
                  Bodoland, officially the Bodoland Territorial Region, is an autonomous region in Assam, Northeast India. It is made up of five districts i.e. Kokrajhar, Chirang, Baksa, Udalguri & Tamulpur.

                  Bodos are known as one of the finest weavers in entire North East region. This traditional art of weaving has been isolated as traditionally followed custom amongst the Bodo women for many centuries. Today this traditional Bodo motifs and colours have greater dimensions to its potential. The fabric produced by the weavers needs skill up-gradation, and the power of information technology to give boost to its handloom (weaving) sector in Bodoland Territorial Council (BTC). The present govt. is trying hard to set up various handloom units in order to provide income option for the below poverty line population but it has not been able to implement it in an organized manner. Within Kokrajhar districts there are numbers of weaving units run by individual, SHGs, NGOs and enterprises. Almost everyone is following the same pattern of weaving as traditionally used fabric/materials.

                </Typography>
              </Grid>
            </Grid>

          </Slide>

        </Box>
        <hr className='mt-4'></hr>
        <div className=" text-black text-center col 10 p-1 mt-5">
          <h3> ABOUT CDAC, Kolkata </h3>
        </div>
        <Box
          component="form"
          display="flex"
          justifyContent="center"
          alignItems="center"

          sx={{
            marginTop: 8,
            // display: "flex",
            // flexDirection: "column"


          }}
          noValidate
          autoComplete="off"
        >
          <Slide right>

            <Grid container spacing={6} direction="row"
              alignItems={"center"}
              justifyContent={"center"}>


              <Grid item xs={12} md={6} lg={6} alignItems="right" >
                <img src={cdac} className="w-20" alt="" />

              </Grid>

              <Grid item xs={12} md={6} lg={6} >
                <Typography variant="subtitle1" align='justify'>
                  C-DAC, Kolkata formerly known as ER&amp;DCI, Calcutta, is one of the constituent units of C-DAC. It is a
                  National Centre of Excellence in pioneering application oriented Research, Design and Development in
                  Electronics, Information Technology and associated areas.

                  C-DAC, Kolkata is an ISO 9001:2008 certified R&amp;D Centre for the Design, Development, Supply,
                  Installation, Commissioning, Turnkey Implementation, Maintenance, Customer Support, Consultancy and
                  Transfer of Technology of Products and Systems in the area of Electronics and Information Technology.
                  This certification is based on the audit conducted by STQC (DeitY), accredited by RvA, Netherlands,
                  facilitating international validity for the ISO 9001:2008 certification.
                  The technology development initiatives of the Centre are focused in the areas of Agri&amp; Environmental
                  Electronics, Knowledge &amp; Perception Engineering, Image Processing, Face Recognition, Software
                  Technologies, Health Informatics, Speech Processing, Information Security, Language Technology, Social
                  Empowerment of Masses through IT, Digitization of Heritage assets, IT Systems &amp; Services and related
                  emerging areas.
                  The activities of Software Technologies include Software Solutions for Social Welfare &amp; Education, Data
                  Warehousing &amp; Data Mining, Web ERP Technologies &amp; Solutions, Heritage Computing: Digital Preservation
                  and Knowledge Engineering &amp; Health Care Solution. Advanced Image Processing (AIP) section is engaged in
                  research in the field of Image Processing and Pattern Recognition. The section also develops products for
                  particularly govt. agencies. The AIP section has collaborations and networking with academic and industrial
                  research institutes as well as different industries of India and abroad.
                </Typography>
              </Grid>
            </Grid>

          </Slide>


        </Box>
        <hr className='mt-4'></hr>
        <div className=" text-black text-center col 10 p-1 mt-5">
          <h3> PHOTO GALLERY </h3>
        </div>
        <Box
          component="form"
          justifyContent="center"
          alignItems="center"

          sx={{
            marginTop: 8,
            // display: "flex",
            // flexDirection: "column"


          }}
          noValidate
          autoComplete="off"
        >
          <Slide up>

            <Grid container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={8}
            >


              <Grid item xs="auto" >
                <Card sx={{ maxWidth: 243, boxShadow: 8 }}>
                  <CardMedia
                    component="img"
                    alt="training image"
                    height="200"
                    image={trainingimg}
                    
                  />
                  <Typography vatiant="h5" sx={{border: 1}}></Typography>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div" align='center' >
                      Training Activities
                    </Typography>
                    {/* <Typography variant="body2" color="text.secondary">
                      Lizards are a widespread group of squamate reptiles, with over 6,000
                      species, ranging across all continents except Antarctica
                    </Typography> */}
                    <Button size="large" onClick={handletrainingclick} variant="contained" fullWidth>View</Button>
                  </CardContent>

                </Card>
              </Grid>
              <Grid item xs="auto" >
                <Card sx={{ maxWidth: 243, boxShadow: 8 }}>
                  <CardMedia
                    component="img"
                    alt="events image"
                    height="200"
                    image={eventimg}
                  />
                  <Typography vatiant="h5" sx={{border: 1}}></Typography>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div" align='center'>
                      Events
                    </Typography>
                    {/* <Typography variant="body2" color="text.secondary">
                      Lizards are a widespread group of squamate reptiles, with over 6,000
                      species, ranging across all continents except Antarctica
                    </Typography> */}
                    <Button size="large" onClick={handleeventclick} variant="contained" fullWidth>View</Button>
                  </CardContent>

                </Card>
              </Grid>
              <Grid item xs="auto" >
                <Card sx={{ maxWidth: 243, boxShadow: 8 }}>
                  <CardMedia
                    component="img"
                    alt="infrastructure image"
                    height="200"
                    image={infraimg}
                  />
                  <Typography vatiant="h5" sx={{border: 1}}></Typography>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div" align='center'>
                      Infrastructure
                    </Typography>
                    {/* <Typography variant="body2" color="text.secondary">
                      Lizards are a widespread group of squamate reptiles, with over 6,000
                      species, ranging across all continents except Antarctica
                    </Typography> */}
                    <Button size="large" onClick={handleinfraclick} variant="contained" fullWidth>View</Button>
                  </CardContent>

                </Card>
              </Grid>
            </Grid>

          </Slide>

          <Modal
            open={modalopenform}
            onClose={handleformmodalClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >

            <Box sx={style}>

              {img && galleryb ? <ImageGallery items={img} showPlayButton={false} autoPlay={true} /> : null}

              <Button size="large" onClick={handlemodalClose}>CLOSE</Button>
            </Box>


          </Modal>




        </Box>

      </div>
    </>
  )
}
