import React, { useEffect, useState } from "react";
import { Cookies } from "react-cookie";
import NavBarAdmin from '../components/NavBarAdmin';
import login from "../images/login.jpg";
import CountUp from "react-countup";
import config from '../components/config.json';
import Avatar from '@mui/material/Avatar';
import axios from "axios";
import { useNavigate } from 'react-router-dom'

import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import Button from '@mui/material/Button';

import Barchart from "../components/Charts/Barchart";
import DounoughtChart from "../components/Charts/DonoughtChart";
import MultiBarChart from "../components/Charts/MultiBarChart";

import * as echarts from 'echarts'
import ReactECharts from 'echarts-for-react';
import AssamJson from "../components/Assam.json"

import { decrypter } from "../Util"

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, Doughnut } from 'react-chartjs-2';

import ChartDataLabels from 'chartjs-plugin-datalabels';




ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

echarts.registerMap('Assam', AssamJson);



const cookies = new Cookies();
//const BASE_URL = "http://10.240.13.181:8888";
const BASE_URL = config.SERVER_URL;
const KEY = config.KEY;
const TITLE = config.TITLE;
const SECRET = config.SECRET;
var CryptoJS = require("crypto-js");

export default function AdminDashBoard() {
  let userdata = decrypter(cookies.get("userdata"))

  // console.log("data ", userdata)
  // console.log("data2 ", userdata.center_id)

  const [center, setcenter] = useState();
  const [ben, setben] = useState();
  const [fac, setfac] = useState();
  const [cert, setcert] = useState();
  const [castestats, setcastestats] = useState([]);
  const [genderstats, setgenderstats] = useState([]);
  const [districtstats, setdistrictstats] = useState([]);
  const [qualstats, setqualstats] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (userdata === "false") {

      navigate('/')

    }
    const headers = {
      'publicKey': KEY

    }

    const params1 = new URLSearchParams();
    params1.append("center_id", userdata.center_id);

    const params2 = new URLSearchParams();
    params2.append("center_id", '');



    const mastercounter = axios.post(BASE_URL + '/mastercounter', params1, { headers: headers })

    const castestats = axios.post(BASE_URL + '/gendercounternew', params2, { headers: headers })

    const genderstats = axios.post(BASE_URL + '/gendercounter', params2, { headers: headers })

    const benbydist = axios.post(BASE_URL + '/benbydistcounter', params2, { headers: headers })

    const benbyqual = axios.post(BASE_URL + '/benbyqualcounter', params2, { headers: headers })


    Promise.all([mastercounter, castestats, genderstats, benbydist, benbyqual])
      .then(function (responses) {
        //console.log("Counts", responses[0].data.Counts)
        //console.log("Caste", responses[1].data.CasteCounter)
        //console.log("Genders ", responses[2].data.Genders)
        //console.log("Dists ", responses[3].data.BenByDist)
        //console.log("Quals ", responses[4].data.Quals)
        //setunmapped(responses.data.Beneficiaries)
        //setbens(responses.data.Beneficiaries)
        //console.log("first", responses[0].data.Counts.centers)
        setcenter(responses[0].data.Counts.centers)
        console.log("gender",responses[1].data.Genders)
        setben(responses[0].data.Counts.bens)
        setfac(responses[0].data.Counts.faculties)
        setcert(responses[0].data.Counts.certificates)
        setcastestats(responses[1].data.Genders)
        setgenderstats(responses[2].data.Genders)
        setdistrictstats(responses[3].data.BenByDist)
        setqualstats(responses[4].data.Quals)

      })
      .catch(function (errors) {
        //console.log("state errors", errors[0]);

      });





  }, [])


  // **************bar chart details****************** //


  let castename = [];
  let castedata = [];
  let totaldata = [];
  let maledata = [];
  let femaledata = [];

  castestats.map((option) => {
    castename.push(option.caste_name)
  })

  castestats.map((option) => {
    castedata.push(option.counts)
  })

  castestats.map((option) => {
    totaldata.push(option.total)
  })

  castestats.map((option) => {
    maledata.push(option.male)
  })

  castestats.map((option) => {
    femaledata.push(option.female)
  })


  // ***********************bar chart details end***************** //

  // ************************donought chart details************************//




  let genders = [];
  let genderdata = [];

  genderstats.map((option) => {
    genderdata.push(option.counts)
  })


  genderstats.map((option) => {
    genders.push(option.gender)
  })



  // **********************donought chart details end****************************** //

  // ************************district chart details********************************* //

  let distname = [];
  let distdata = [];

  districtstats.map((option) => {
    distname.push(option.district_name)
  })

  districtstats.map((option) => {
    distdata.push(option.count)
  })


  //console.log("name  ", distname)
  //console.log("count ", distdata)



  // *****************************district chart details end*************************************//

  //******************************qual chart details***********************************//

  let qualnames = [];
  let qualdata = [];

  qualstats.map((option) => {
    qualnames.push(option.qual_name)
  })

  qualstats.map((option) => {
    qualdata.push(option.count)
  })

  //******************************qual chart details end********************************** */


  //********************************echart options ***************************************/


  const option = {

    title: {
      text: 'District Wise Registration',
      subtext: 'Registration',
      left: 'right'
    },
    tooltip: {
      trigger: 'item',
      showDelay: 0,
      transitionDuration: 0.2
    },
    visualMap: {
      left: 'right',
      min: 0,
      max: 200,
      inRange: {
        color: [
          '#313695',
          '#4575b4',
          '#74add1',
          '#abd9e9',
          '#e0f3f8',
          '#ffffbf',
          '#fee090',
          '#fdae61',
          '#f46d43',
          '#d73027',
          '#a50026'
        ]
      },
      text: ['High', 'Low'],
      calculable: true
    },
    toolbox: {
      show: true,
      //orient: 'vertical',
      left: 'left',
      top: 'top',
      feature: {
        dataView: { readOnly: false },
        restore: {},
        saveAsImage: {}
      }
    },
    series: [
      {
        name: 'Assam',
        type: 'map',
        roam: true,
        map: 'Assam',
        emphasis: {
          
          label: {
            show: true
          }
        },
        data: [
          { name: 'Kokrajhar', value: 77 },
          { name: 'Baksa', value: 157 },
          { name: 'Chirang', value: 16 },
          { name: 'Udalguri', value: 2 },
          { name: 'Tamulpur', value: 3 }
        ]
      }
    ]
  };


  //********************************echart option ends **************************************/



  return (
    <>
      <div >
        <NavBarAdmin
          title={TITLE}
          name={userdata.faculty_name}
          role={userdata.role_name}
          state={userdata.district_name}
          image={userdata.faculty_image}
        />
        {/* carousel starts */}
        <div className="row">
          <div className="container ">
            <div
              id="carouselExampleIndicators"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-indicators">
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="0"
                  className="active"
                  aria-current="true"
                  aria-label="Slide 1"
                ></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src={login}
                    className="d-block w-100"
                    alt="Max-width 100%"
                  />
                </div>
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Next</span>
              </button>
            </div>
          </div>
        </div>
        {/* carousel ends */}
        {/* {card stats starts} */}
        <div className="col-lg-12 main" style={{ minHeight: "20vh", flexDirection: "column" }}>
          <div className="container-fluid mt-5 d-flex justify-content-center">
            <div className="row mb 3 ">
              {/* <div className="col-xl-3 col-lg-6 col-md-3">
                    <div
                      className="card text-white bg-danger"
                      style={{ width: "18rem" }}
                    >
                      <div className="card-body">
                        <h5 className="card-title">Total Centres</h5>
                        <h1 className="card-text">
                          <CountUp end={center} />
                        </h1>
                      </div>
                    </div>
                  </div> */}
              <div className="col-xl-4 col-lg-6 col-md-4">
                <div
                  className="card text-white bg-info"
                  style={{ width: "18rem" }}
                >
                  <div className="card-body">
                    <h5 className="card-title">Beneficiaries Registered</h5>
                    <h1 className="card-text">
                      <CountUp end={4635} />
                    </h1>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-4">
                <div
                  className="card text-white bg-secondary"
                  style={{ width: "18rem" }}
                >
                  <div className="card-body">
                    <h5 className="card-title">Certificate Issued</h5>
                    <h1 className="card-text">
                      <CountUp end={4328} />
                    </h1>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-4">
                <div
                  className="card text-white bg-success"
                  style={{ width: "18rem" }}
                >
                  <div className="card-body">
                    <h5 className="card-title">Faculties Registered</h5>
                    <h1 className="card-text">
                      <CountUp end={fac} />
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* {card stats ends} */}
        
        <Box
          component="form"
          sx={{
            marginTop: 1,
            marginLeft: 4,
            marginRight: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center"

          }}
          noValidate
          autoComplete="off"
        >
          <Grid container
            direction="row"
            justifyContent="center"
            alignItems="center" >
            <Grid item xs={5} boxShadow={4} >


              <MultiBarChart label={castename} data1={totaldata} data2={maledata} data3={femaledata} title={"Caste Wise Registered Beneficiaries"}></MultiBarChart>



            </Grid>
            <Grid item xs={1}>

            </Grid>
            <Grid item xs={5} boxShadow={4} >

              <DounoughtChart label={genders} data={genderdata} title={"Gender Wise Registered Beneficiaries"}></DounoughtChart>



            </Grid>
            <Grid item xs={12} sx={{ marginTop: 5 }}></Grid>
            <Grid item xs={5} boxShadow={4} >


              <Barchart label={distname} data={distdata} title={"District Wise Registered Beneficiaries"}></Barchart>



            </Grid>

            <Grid item xs={1}></Grid>
            <Grid item xs={5} boxShadow={4} >


              <Barchart label={qualnames} data={qualdata} title={"Qualification Wise Registered Beneficiaries"}></Barchart>



            </Grid>
            <Grid item xs={12} sx={{ marginTop: 5 }}></Grid>


            <Grid item xs={5} boxShadow={4}  >


              <ReactECharts option={option} />



            </Grid>

            <Grid item xs={1}></Grid>
            <Grid item xs={5} boxShadow={4} >

            </Grid>






          </Grid>

        </Box>
      </div>

    </>
  );
}
