import React, { useEffect, useState } from 'react'
import { Cookies } from 'react-cookie';
import login from '../images/login.jpg';
import config from "../components/config.json";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FileOpenIcon from '@mui/icons-material/FileOpen';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faTrashCan, faPlus, faFilePen } from '@fortawesome/free-solid-svg-icons'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import {
    DataGrid, GridToolbar,
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector,
    gridPageSizeSelector
} from '@mui/x-data-grid';
import Snackbar from '@mui/material/Snackbar';
import Avatar from '@mui/material/Avatar';
import Modal from '@mui/material/Modal';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Grid } from "@mui/material";
import { faFileArrowUp } from "@fortawesome/free-solid-svg-icons";
//import { Document, Page, ReactPDF } from "react-pdf";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import Alert from '@mui/material/Alert';

import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { decrypter } from "../Util"
import moment from 'moment';
import NavBarAdmin from "../components/NavBarAdmin";

import Pagination from '@mui/material/Pagination';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';

import MediaQuery from 'react-responsive'
import { useMediaQuery } from 'react-responsive'




const cookies = new Cookies();
const BASE_URL = config.SERVER_URL;
const KEY = config.KEY;
const TITLE = config.TITLE;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,

};

const style2 = {
    position: 'absolute',
    left: '50%',
    top: '50%',
    maxWidth: '100%',
    maxHeight: '100vh',
    transform: 'translate(-50%, -50%)',
    overflowY: 'auto',
    margin: 'auto'


}

const stylepdf = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,

};

const useStyle = makeStyles({
    root: {
        "& .MuiDataGrid-columnHeaderTitle": {
            textOverflow: "clip",
            whiteSpace: "pre-wrap",
            lineHeight: 1,
            textAlign: "center"
        },
        "& .MuiDataGrid-cellContent": {
            textOverflow: "clip",
            whiteSpace: "pre-wrap",
            lineHeight: 2,
            textAlign: "center"
        },
        boxShadow: 2,
        '& .MuiTablePagination-selectLabel': {
            marginTop: 'auto'
        },
        '& .MuiTablePagination-displayedRows': {
            marginTop: 'auto'
        },
        "& .MuiTypography-string": {
            textOverflow: "clip",
            whiteSpace: "pre-wrap",
            lineHeight: 2,
            textAlign: "center"
        },
    
    }
});



export default function AdminBeneficiary() {
    let userdata = decrypter(cookies.get("userdata"))
    const navigate = useNavigate();

    const classes = useStyle();
    
    const [pageSize, setPageSize] = useState(10);
    const [gridRef, setGridRef] = useState(true);

    const [bens, setbens] = useState([]);
    const [open, setOpen] = useState(false);
    const [click, setclick] = useState(false);
    const [del, setdel] = useState('')
    const [dialog, setdialog] = useState(false)

    const [modalopen, setmodalopen] = useState(false)
    const [modalopen2, setmodalopen2] = useState(false)

    const [bid, setbid] = useState('')
    const [img, setimg] = useState('')
    const [bname, setbname] = useState('')

    const [preview, setpreview] = useState()
    const [filename, setFilename] = useState("");
    const [file, setfile] = useState();
    const [btncolor, setbtncolor] = useState("primary")
    const [errbtn, seterrbtn] = useState("")

    const [msg, setmsg] = useState('')

    const [enrollid, setenrollid] = useState('');

    const [formname, setformname] = useState('');

    const [openalertimg, setopenalertimg] = useState(false)
    const [imagealert, setimagealert] = useState("")
    const [hiddenb, sethiddenb] = useState(true)

    const [anchorEl, setAnchorEl] = useState(undefined);
    const menuopen = Boolean(anchorEl);
    const handleIconClick = (event) => {
        setAnchorEl(event.target);
        //console.log("anchorel ", anchorEl)

    };
    const handleMenuClose = () => {
        setAnchorEl(undefined);
    };

    const [modalopenform, setmodalopenform] = useState(false)

    const [getformname, setgetformname] = useState('')
    const [formfilename, setformFilename] = useState("");
    const [formfile, setformfile] = useState();
    const [openalertform, setopenalertform] = useState(false)
    const [formalert, setformalert] = useState("")
    const [formbtncolor, setformbtncolor] = useState("primary")
    const [formerrbtn, setformerrbtn] = useState("")

    const [viewformModal, setviewformModal] = useState(false)
    const [viewcell, setviewcell] = useState([])


    const isBigScreen = useMediaQuery({ query: '(min-height: 100vh)' })




    const columns = [
        {
            field: 'ben_id', headerName: 'ID', flex: 0.3, align: 'center', headerAlign: 'center'
        },
        {
            field: 'enrollment_id', headerName: ' Enrollement ID', flex: 0.7, align: 'center', headerAlign: 'center'
        },

        {
            field: 'Image', headerName: 'Image', flex: 0.5, align: 'center', headerAlign: 'center',
            renderCell: (cellValues) => {
                if (cellValues.row.ben_image === null) {
                    return (
                        <Avatar alt={cellValues.row.ben_name}
                            src={cellValues.row.ben_name}
                            sx={{ width: 40, height: 40 }}
                            variant="circular"
                            onClick={(event) => {
                                handleimageclick(event, cellValues.row);
                            }} />
                    );
                }
                else {
                    return (
                        <Avatar alt={cellValues.row.ben_name}
                            src={BASE_URL + "/" + "images" + "/" + cellValues.row.ben_image}
                            sx={{ width: 40, height: 40 }}
                            variant="circular"
                            onClick={(event) => {
                                handleimageclick(event, cellValues.row);
                            }} />
                    );
                }
            }
        },

        {
            field: 'center_name', headerName: 'Center Name', flex: 1, align: 'center', headerAlign: 'center'
        },
        {
            field: 'ben_name', headerName: 'Name', flex: 1.2, align: 'center', headerAlign: 'center',
            renderCell: (params) => {
                return (<Typography variant="string" textTransform="uppercase">
                    {params.row.ben_name}
                </Typography>)
            }
        },
        {
            field: 'ben_dob', headerName: 'DOB', flex: 0.5, align: 'center', headerAlign: 'center',
            renderCell: (params) => {
                return (<Typography variant="string" textTransform="">
                    {params.row.ben_dob === null ? "Not Provided" : moment(params.row.ben_dob).format('DD/MM/YYYY')}
                </Typography>)
            }
        },
        ,
        {
            field: 'caste_name', headerName: 'Caste', flex: 0.5, align: 'center', headerAlign: 'center'
        },
        // {
        //     field: 'ben_email_id', headerName: 'Email ID', flex: 1.5, align: 'center', headerAlign: 'center',
        //     valueGetter: (cellValues) => {
        //         let result = [];
        //         if (cellValues.row.ben_email_id) {
        //             if (cellValues.row.ben_email_id) {

        //                 result.push(cellValues.row.ben_email_id);
        //             }
        //         }
        //         else {
        //             result = ["Email Not Provided"]
        //         }
        //         return result;
        //     }
        // },
        {
            field: 'ben_mobile_no', headerName: 'Phone', flex: 0.6, align: 'center', headerAlign: 'center',
            renderCell: (params) => {
                return (<Typography variant="string" textTransform="">
                    {params.row.ben_mobile_no === null ? "Not Provided" : params.row.ben_mobile_no}
                </Typography>)
            }
        },
        {
            field: 'session_name', headerName: 'Session Name', flex: 0.7, align: 'center', headerAlign: 'center'
        },
        {
            field: 'updated_date', headerName: 'Updated Date', flex: 0.7, align: 'center', headerAlign: 'center'
        },
        // {
        //     field: "Action",
        //     renderCell: (cellValues) => {
        //         return (
        //             <>
        //                 {/* <IconButton
        //                     //   variant="contained"
        //                     //   color="primary"
        //                     //   size='medium'
        //                     //   startIcon={<EditIcon></EditIcon>}

        //                     onClick={(event) => {
        //                         handleEditClick(event, cellValues);
        //                     }}
        //                 >
        //                     <EditIcon></EditIcon>
        //                 </IconButton> */}
        //                 <IconButton
        //                     //   variant="contained"
        //                     //   color="primary"
        //                     //   size='medium'
        //                     //   startIcon={<EditIcon></EditIcon>}

        //                     onClick={(event) => {
        //                         handleClickOpen(event, cellValues);
        //                     }}
        //                 >
        //                     <DeleteIcon></DeleteIcon>
        //                 </IconButton>
        //                 <IconButton
        //                     //   variant="contained"
        //                     //   color="primary"
        //                     //   size='medium'
        //                     //   startIcon={<EditIcon></EditIcon>}

        //                     onClick={(event) => {
        //                         handleFormEditOpen(event, cellValues.row);
        //                     }}
        //                 >
        //                     <FileOpenIcon></FileOpenIcon>
        //                 </IconButton>
        //             </>
        //         );
        //     }, flex: 1, align: 'center', headerAlign: 'center'
        // },
        {
            field: "View",
            renderCell: (cellValues) => {
                return (
                    <IconButton
                        //   variant="contained"
                        //   color="primary"
                        //   size='medium'
                        //   startIcon={<EditIcon></EditIcon>}

                        onClick={(event) => {
                            //handleViewOpen(event, cellValues);
                            handleviewformModal(event, cellValues);
                        }}
                    >
                        <DesktopWindowsIcon></DesktopWindowsIcon>
                    </IconButton>
                );
            }, flex: 0.4, align: 'center', headerAlign: 'center'
        },
        {
            field: "View Form",
            renderCell: (cellValues) => {
                return (
                    <>
                        <IconButton
                            //   variant="contained"
                            //   color="primary"
                            //   size='medium'
                            //   startIcon={<EditIcon></EditIcon>}

                            onClick={(event) => {
                                handleViewFormOpen(event, cellValues);
                                //window.open(BASE_URL + "/" + "forms" + "/" + cellValues.row.ben_reg_form,"","location=yes,width=600,height=480,toolbar=no,menubar=no,resizable=yes")
                                //window.open('file:///C:/benForm/BTC02B0006.pdf')
                                //const url = BASE_URL + "/" + "forms" + "/" + cellValues.row.ben_reg_form;
                                //console.log("url is ", url)
                                //<iframe src= "url" width="800" height="600"></iframe>
                                // <iframe src="https://research.google.com/pubs/archive/44678.pdf"
                                //     width="800" height="600"></iframe>
                            }}

                        >

                            <InsertDriveFileIcon></InsertDriveFileIcon>
                        </IconButton>


                        {/* <a href={BASE_URL + "/" + "forms" + "/" + cellValues.row.ben_reg_form} target="_blank" rel="noopener noreferrer"><DesktopWindowsIcon></DesktopWindowsIcon></a>
                    <a href='file:///C:\benForm\BTC02B0006.pdf' className='pdf' target="_blank" rel="noopener noreferrer">View</a> */}
                    </>


                );
            }, flex: 0.4, align: 'center', headerAlign: 'center'
        }

    ]


    useEffect(() => {

        if (userdata === "false") {

            navigate('/')

        }

        const headers = {
            'publicKey': KEY

        }

        const params = new URLSearchParams();
        params.append("center_id", userdata.center_id);

        axios.post(BASE_URL + '/getAllBeneficiariesAdmin', {username: "abc"}, { headers: headers })
            .then(function (responses) {

                //console.log("Beneficiaries", responses.data.Beneficiaries)
                //setunmapped(responses.data.Beneficiaries)
                setbens(responses.data.Beneficiaries)

            })
            .catch(function (errors) {

            })
    }, [click])

    useEffect(() => {
        if (!file) {
            setpreview(undefined)
            sethiddenb(true)
            return
        }

        const objectUrl = URL.createObjectURL(file)
        sethiddenb(false)
        setpreview(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [file])

    const handleimageclick = (event, image) => {

        setmodalopen(true)
        setbid(image.ben_id)
        setimg(image.ben_image)
        setbname(image.ben_name)
        setenrollid(image.enrollment_id)





    }

    const handlefilechange = (e) => {
        //const [files] = e.target.files;
        //console.log("data", files);
        setFilename(e.target.files[0].name);
        setfile(e.target.files[0]);



        //setFilename(e.target.files);
    };

    const handleFormEditOpen = (event, cellValues) => {

        setmodalopenform(true)
        setbid(cellValues.ben_id)
        //setimg(cellValues.ben_image)
        setgetformname(cellValues.ben_reg_form)
        setbname(cellValues.ben_name)
        setenrollid(cellValues.enrollment_id)

    }

    const handleformfilechange = (e) => {
        //const [files] = e.target.files;
        //console.log("data", files);
        setformFilename(e.target.files[0].name);
        setformfile(e.target.files[0]);



        //setFilename(e.target.files);
    };


    const handlemodalOpen = () => {
        setmodalopen(true);

    };
    const handlemodalClose = () => {
        setmodalopen(false);
    };

    const handlemodalClose2 = () => {
        setmodalopen2(false);
    };

    const handleformmodalClose = () => {
        setmodalopenform(false);
    };
    const handleCloseDialog = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setdialog(false);
    };

    const handleClickOpen = (event, cellValues) => {
        setOpen(true);
        setdel(cellValues.row.ben_id)
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleViewOpen = (event, cellValues) => {

        navigate('/viewBen', {
            state: {
                data: cellValues.row

            }
        })

    }

    const handleViewFormOpen = (event, cellValues) => {

        setmodalopen2(true)
        const ab = BASE_URL + "/" + "forms" + "/" + cellValues.row.ben_reg_form
        setformname(ab)
        //console.log("formname ", formname);

        //var FileSaver = require('file-saver');
        //FileSaver.saveAs(BASE_URL + "/" + "forms" + "/" + cellValues.row.ben_reg_form, "cellValues.row.ben_reg_form.pdf");
        // fetch(BASE_URL + "/" + "forms" + "/" + cellValues.row.ben_reg_form).then(response => {
        //     response.blob().then(blob => {
        //         // Creating new object of PDF file
        //         const fileURL = window.URL.createObjectURL(blob);
        //         // Setting various property values
        //         let alink = document.createElement('a');
        //         alink.href = fileURL;
        //         alink.download = cellValues.row.ben_reg_form;
        //         alink.click();
        //     })
        // })





    }

    const handleEditClick = (event, cellValues) => {
        navigate('/editBeneficiary', {
            state: {
                bid: cellValues.row.ben_id, stateid: cellValues.row.ben_state_id,
                districtid: cellValues.row.ben_dist_id, dname: cellValues.row.district_name, cid: cellValues.row.center_id,
                bname: cellValues.row.ben_name, fname: cellValues.row.ben_fname,
                dob: cellValues.row.ben_dob, address: cellValues.row.ben_address,
                email: cellValues.row.ben_email_id, mob: cellValues.row.ben_mobile_no,
                sid: cellValues.row.session_id, idtype: cellValues.row.ben_id_type,
                idproof: cellValues.row.ben_id_proof_no, casteid: cellValues.row.ben_caste_id,
                apl: cellValues.row.apl_bpl, qual: cellValues.row.ben_qual, course: cellValues.row.ben_course_opted,
                gender: cellValues.row.gender, relation: cellValues.row.relation, enrollment: cellValues.row.enrollment_id

            }
        })

    }

    const handleDelete = () => {

        const headers = {
            'publicKey': KEY
        }

        //console.log("del", del)

        const data = new FormData();
        data.append('model', JSON.stringify({
            benId: del
        }))

        axios.post(BASE_URL + '/delBeneficiary', data, { headers: headers })
            .then(function (responses) {

                //console.log(responses.data)
                if (responses.data === 'OK') {

                    setclick(true)
                    setmsg("Beneficiary Deleted")
                    setdialog(true);
                }
            })
            .catch(function (errors) {

            })
        setOpen(false);
        setclick(false)

    }

    const handlesubmitclick = () => {

        //console.log("valid", validation())

        if (validation()) {

            const headers = {
                publicKey: KEY,

            };

            const data = new FormData();
            data.append("file", file);

            data.append(
                "model",
                JSON.stringify({
                    benId: bid,
                    enrollment_id: enrollid,
                    updated_by: userdata.faculty_sys_id
                })
            );

            axios
                .post(BASE_URL + "/updateimgBeneficiary", data, { headers: headers })
                .then(function (response) {
                    //console.log(response.data);
                    if (response.data === "OK") {
                        setmsg("Image Updated")
                        setdialog(true);
                        setTimeout(function () {
                            window.location.reload(false);
                        }, 3000);

                    }
                })
                .catch(function (errors) { });

        }

    }

    const handleformsubmitclick = () => {

        //console.log("validform", validationform())

        if (validationform()) {

            const headers = {
                publicKey: KEY,

            };

            const data = new FormData();
            data.append("file", formfile);

            data.append(
                "model",
                JSON.stringify({
                    benId: bid,
                    enrollment_id: enrollid,
                    updated_by: userdata.faculty_sys_id
                })
            );

            axios
                .post(BASE_URL + "/updateformBeneficiary", data, { headers: headers })
                .then(function (response) {
                    //console.log(response.data);
                    if (response.data === "OK" && response.status == 202) {
                        setmsg("Image Updated")
                        setdialog(true);
                        setTimeout(function () {
                            window.location.reload(false);
                        }, 3000);

                    }
                })
                .catch(function (errors) { });

        }
    }

    function validation() {
        let isvalid = true

        if (file === undefined) {
            setbtncolor("error")
            seterrbtn("Please Upload Image")
            isvalid = false

        } else {
            setbtncolor("primary")
            seterrbtn("")


        }

        if (file === undefined) {
            setbtncolor("error")
            seterrbtn("Please Upload Image")
            setopenalertimg(true)
            setimagealert("Please Upload Image")
            isvalid = false

        } else if (file.size > 512000) {

            setbtncolor("error")
            seterrbtn("Image Size is Greater than 500kb")
            setopenalertimg(true)
            setimagealert("Image Size is Greater than 500kb")
            //alert("Image size is greater than 500kb")
            isvalid = false

        } else {
            setbtncolor("primary")
            seterrbtn("")
            setopenalertimg(false)
            setimagealert("")

        }



        return isvalid
    }

    function validationform() {
        let isvalid = true



        if (formfile === undefined) {
            setformbtncolor("error")
            setformerrbtn("Please Upload Form")
            setopenalertform(true)
            setformalert("Please Upload Form")
            isvalid = false

        } else if (formfile.size > 512000) {

            setformbtncolor("error")
            setformerrbtn("File Size is Greater than 500kb")
            setopenalertform(true)
            setformalert("File Size is Greater than 500kb")
            //alert("Image size is greater than 500kb")
            isvalid = false

        } else {
            setformbtncolor("primary")
            setformerrbtn("")
            setopenalertform(false)
            setformalert("")

        }



        return isvalid
    }


    const handleviewformModal = (event, cellValues) => {

        setviewformModal(true)
        //console.log("hello", cellValues.row.ben_name)
        setviewcell(cellValues.row)

    }

    const handleviewformModalClose = () => {

        setviewformModal(false)
    }

    




    return (
        <>
            <NavBarAdmin
                title={TITLE}
                name={userdata.faculty_name}
                role={userdata.role_name}
                state={userdata.district_name}
                image={userdata.faculty_image}
            />
            {/* carousel starts */}
            <div className="row">
                <div className="container ">
                    <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={login} className="d-block w-100" alt="Max-width 100%" />
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </button>
                    </div>
                </div>
            </div>
            {/* carousel ends */}
            <div className="row mx-5 mb-5">
                <div className="container">
                    <h1>
                        <div className="abc text-white text-center col 10 p-1 mt-1">
                            <h3> Beneficiary Details </h3>
                        </div>
                    </h1>
                    <Box
                    //justifyContent='center'
                    //</div>sx={{ height: 700, width: '100%' }}
                    >
                        <DataGrid

                            className={classes.root}
                            sx={{

                            }}

                            rows={bens}
                            getRowId={(r) => r.ben_id}
                            columns={columns}
                            components={{
                                Toolbar: GridToolbar,
                                //Pagination: CustomPagination
                            }}
                            autoHeight
                            handle={setGridRef}
                            enableColumnAutosize
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            pagination
                            rowsPerPageOptions={[10, 25, 50, 100]}
                            disableVirtualization


                        //checkboxSelection={true}
                        // onSelectionModelChange={(newSelectionModel) => {
                        //     setSelectedRows(newSelectionModel);
                        // }}

                        />


                    </Box>


                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"ARE YOU SURE YOU WANT TO DELETE?"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Once deleted it cannot be undone
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleDelete}>YES</Button>
                            <Button onClick={handleClose} autoFocus>
                                NO
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Snackbar
                        open={dialog}
                        autoHideDuration={6000}
                        onClose={handleCloseDialog}
                        message={msg}
                    //action={action}
                    />
                    <Modal
                        open={modalopen}
                        onClose={handlemodalClose}
                        aria-labelledby="parent-modal-title"
                        aria-describedby="parent-modal-description"
                    >
                        <Box sx={style}>
                            <Avatar alt={bname}
                                src={BASE_URL + "/" + "images" + "/" + img}
                                sx={{ width: 100, height: 100, alignItems: 'center' }}
                                variant="circular"

                            />
                            <Grid item xs={4}>
                                <div>
                                    <Button
                                        margin="normal"
                                        fullWidth
                                        required
                                        component="label"
                                        variant="outlined"
                                        sx={{ mt: 2 }}
                                        startIcon={<FontAwesomeIcon icon={faFileArrowUp} />}
                                        color={btncolor}
                                        helperText={errbtn}
                                    >
                                        Upload Image (jpg, jpeg & png ) Less than 500KB

                                        <input
                                            type="file"
                                            accept=".jpg,.jpeg,.png"
                                            name={file}
                                            hidden
                                            onChange={handlefilechange}
                                        />
                                    </Button>
                                </div>
                                {filename} <img src={preview} hidden={hiddenb} alt="" width="100" height="100" />
                            </Grid>
                            <Grid item xs={4}>
                                <Collapse in={openalertimg}>
                                    <Alert severity="error"
                                        action={
                                            <IconButton
                                                aria-label="close"
                                                color="inherit"
                                                size="small"
                                                onClick={() => {
                                                    setopenalertimg(false);
                                                }}
                                            >
                                                <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                        }
                                        sx={{ mb: 2 }}
                                    >
                                        {imagealert}
                                    </Alert>
                                </Collapse>
                            </Grid>
                            <Grid container justifyContent="center" sx={{ mt: 3, mb: 3 }}>
                                <Button variant="contained" onClick={handlesubmitclick}>
                                    Submit
                                </Button>
                            </Grid>

                        </Box>
                    </Modal>

                    {/* pdf modal */}
                    <Modal
                        open={modalopen2}
                        onClose={handlemodalClose2}
                        aria-labelledby="parent-modal-title"
                        aria-describedby="parent-modal-description"
                    >


                        <p align='center'><iframe src={formname}
                            width="800" height="800" margin='auto'></iframe></p>




                    </Modal>
                    {/* form modal */}
                    <Modal
                        open={modalopenform}
                        onClose={handleformmodalClose}
                        aria-labelledby="parent-modal-title"
                        aria-describedby="parent-modal-description"
                    >
                        <Box sx={style}>
                            <Grid item xs={4}>
                                <div>
                                    <Button
                                        margin="normal"
                                        fullWidth
                                        required
                                        component="label"
                                        variant="outlined"
                                        sx={{ mt: 2 }}
                                        startIcon={<FontAwesomeIcon icon={faFileArrowUp} />}
                                        color={formbtncolor}
                                        helperText={formerrbtn}
                                    >
                                        Upload Form (pdf) Less than 500KB

                                        <input
                                            type="file"
                                            accept=".pdf"
                                            name={formfile}
                                            hidden
                                            onChange={handleformfilechange}
                                        />
                                    </Button>
                                </div>
                                {formfilename}
                            </Grid>
                            <Grid item xs={4}>
                                <Collapse in={openalertform}>
                                    <Alert severity="error"
                                        action={
                                            <IconButton
                                                aria-label="close"
                                                color="inherit"
                                                size="small"
                                                onClick={() => {
                                                    setopenalertform(false);
                                                }}
                                            >
                                                <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                        }
                                        sx={{ mb: 2 }}
                                    >
                                        {formalert}
                                    </Alert>
                                </Collapse>
                            </Grid>
                            <Grid container justifyContent="center" sx={{ mt: 3, mb: 3 }}>
                                <Button variant="contained" onClick={handleformsubmitclick}>
                                    Submit
                                </Button>
                            </Grid>

                        </Box>
                    </Modal>
                    {/* view form modal */}
                    {isBigScreen && <Modal
                        open={viewformModal}
                        onClose={handleviewformModalClose}
                        aria-labelledby="parent-modal-title"
                        aria-describedby="parent-modal-description"
                    >
                        <Box sx={style2}>
                            <Grid item xs={4}>
                                <Card sx={{ width: 'auto', height: 'auto', alignItems: 'center', boxShadow: 10, borderRadius: 3, padding: 2 }}>
                                    <CardHeader
                                        avatar={
                                            viewcell.ben_image === null ? <Avatar alt={viewcell.ben_name}
                                                src={viewcell.ben_name}
                                                sx={{ width: 100, height: 100 }}
                                                variant="rounded"
                                                style={{ alignItems: 'center' }} /> : <Avatar alt={viewcell.ben_name}
                                                    src={BASE_URL + "/" + "images" + "/" + viewcell.ben_image}
                                                    //src={"C:/benImg" + "/" + viewcell.ben_image}
                                                    sx={{ width: 100, height: 100 }}
                                                    variant="rounded"
                                                    style={{ alignItems: 'center' }} />
                                        }
                                        title={viewcell.ben_name}
                                        subheader={viewcell.ben_email_id}
                                        style={{ textAlign: 'left' }}
                                        titleTypographyProps={{ variant: 'h3', textTransform: 'uppercase' }}
                                        subheaderTypographyProps={{ variant: 'h5', }}

                                    >
                                    </CardHeader>
                                    <Divider >DETAILS</Divider>
                                    <CardContent>
                                        <Box
                                            component="form"
                                            sx={{
                                                marginTop: 4,
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",

                                            }}
                                            noValidate
                                            autoComplete="off"
                                        >
                                            <Grid container spacing={3} >
                                                <Grid item xs={2} >

                                                    <Typography sx={{ fontWeight: 500 }} variant="subtitle1">
                                                        Beneficiary ID:
                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={3}>

                                                    <Typography variant="subtitle1" >
                                                        {viewcell.ben_id}
                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={1}>

                                                    <Typography variant="subtitle1">

                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={2} >

                                                    <Typography sx={{ fontWeight: 500 }} variant="subtitle1" >
                                                        State Name:
                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={3} >

                                                    <Typography variant="subtitle1" >
                                                        {viewcell.state_name}
                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={2} >

                                                    <Typography sx={{ fontWeight: 500 }} variant="subtitle1">
                                                        District Name:
                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={3}>

                                                    <Typography variant="subtitle1">
                                                        {viewcell.district_name}
                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={1}>

                                                    <Typography variant="subtitle1">

                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={2} >

                                                    <Typography sx={{ fontWeight: 500 }} variant="subtitle1">
                                                        Date of Birth:
                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={3}>

                                                    <Typography variant="subtitle1">
                                                        {/* {moment(viewcell.ben_dob).format('DD/MM/YYYY')} */}
                                                        {viewcell.ben_dob === null ? "Not Provided" : moment(viewcell.ben_dob).format('DD/MM/YYYY')}
                                                        {/* {viewcell.ben_dob} */}
                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={2} >

                                                    <Typography sx={{ fontWeight: 500 }} variant="subtitle1">
                                                        Guardian Name:
                                                    </Typography>

                                                </Grid>

                                                <Grid item xs={3}>

                                                    <Typography variant="subtitle1">
                                                        {viewcell.ben_fname}
                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={1}>

                                                    <Typography variant="subtitle1">

                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={2} >

                                                    <Typography sx={{ fontWeight: 500 }} variant="subtitle1">
                                                        Relation:
                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={3}>

                                                    <Typography variant="subtitle1">
                                                        {viewcell.relation == "F" ? "Father" : (viewcell.relation == "M" ? "Mother" : "Husband")}
                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={1}>

                                                    <Typography variant="subtitle1">

                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={2} >

                                                    <Typography sx={{ fontWeight: 500 }} variant="subtitle1">
                                                        Address:
                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={3}>

                                                    <Typography variant="subtitle1">
                                                        {viewcell.ben_address}
                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={1}>

                                                    <Typography variant="subtitle1">

                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={2} >

                                                    <Typography sx={{ fontWeight: 500 }} variant="subtitle1">
                                                        Mobile:
                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={3}>

                                                    <Typography variant="subtitle1">
                                                        {viewcell.ben_mobile_no === null ? "Not Provided" : viewcell.ben_mobile_no}
                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={2} >

                                                    <Typography sx={{ fontWeight: 500 }} variant="subtitle1">
                                                        Proof ID Type:
                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={3}>

                                                    <Typography variant="subtitle1">
                                                        {viewcell.proof_type === null ? "Not Provided" : viewcell.proof_type}
                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={1}>

                                                    <Typography variant="subtitle1">

                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={2} s>

                                                    <Typography sx={{ fontWeight: 500 }} variant="subtitle1">
                                                        ID Number:
                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={3}>

                                                    <Typography variant="subtitle1">
                                                        {viewcell.ben_id_proof_no === "" ? "Not Provided" : viewcell.ben_id_proof_no}
                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={2} >

                                                    <Typography sx={{ fontWeight: 500 }} variant="subtitle1">
                                                        Caste:
                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={3}>

                                                    <Typography variant="subtitle1">
                                                        {viewcell.caste_name}
                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={1}>

                                                    <Typography variant="subtitle1">

                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={2} >

                                                    <Typography sx={{ fontWeight: 500 }} variant="subtitle1">
                                                        Gender:
                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={3}>

                                                    <Typography variant="subtitle1">
                                                        {viewcell.gender}
                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={2} >

                                                    <Typography sx={{ fontWeight: 500 }} variant="subtitle1">
                                                        Qualification:
                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={3}>

                                                    <Typography variant="subtitle1">
                                                        {viewcell.qual_name}
                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={1}>

                                                    <Typography variant="subtitle1">

                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={2} >

                                                    <Typography sx={{ fontWeight: 500 }} variant="subtitle1">
                                                        Course Opted:
                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={3}>

                                                    <Typography variant="subtitle1">
                                                        {viewcell.course_name}
                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={2} >

                                                    <Typography sx={{ fontWeight: 500 }} variant="subtitle1">
                                                        Updated By:
                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={3}>

                                                    <Typography variant="subtitle1">
                                                        {viewcell.faculty_name}
                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={1}>

                                                    <Typography variant="subtitle1">

                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={2} >

                                                    <Typography sx={{ fontWeight: 500 }} variant="subtitle1">
                                                        Updated Date:
                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={3}>

                                                    <Typography variant="subtitle1">

                                                        {
                                                            moment(viewcell.updated_date).format('MMMM Do YYYY')
                                                        }
                                                    </Typography>

                                                </Grid>

                                            </Grid>

                                        </Box>
                                    </CardContent>

                                </Card>
                            </Grid>

                        </Box>
                    </Modal>}


                </div>
            </div>
        </>
    )
}
